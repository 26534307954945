import { BiSolidLockAlt } from "react-icons/bi";

interface Props {
  event?: any;
  style?: string;
}

const Button = ({ event = () => { }, style = "w-full" }: Props) => {
  return (
    <button
      onClick={event}
      className={`${style} h-[25px] sm:h-[30px] md:h-[40px] flex justify-center items-center button-colors hover:opacity-80 font-semibold rounded-md capitalize px-1`}
    >
      <BiSolidLockAlt className="primary-color text-[12px] sm:text-[15px] md:text-[18px]" />
    </button>
  );
};

export default Button;
