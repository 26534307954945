import axios from "axios";

export class BackendCommunicator {
  private static SERVICE_ENDPOINT = "https://demo-backend.sunscreen.tech";//"http://127.0.0.1";

  public static async getPublicKey(): Promise<string> {
    const paramsResponse = await axios.get(
      BackendCommunicator.SERVICE_ENDPOINT + '/public_key',
      {
        headers: {
          'Content-Type': 'text/plain',
          'Access-Control-Allow-Origin': '*',
        }
      }
    );

    return paramsResponse.data["value"];
  };

  public static async decrypt(base: string): Promise<void> {
    const paramsResponse = await axios.post(
      BackendCommunicator.SERVICE_ENDPOINT + '/decrypt',
      base,
      {
        headers: {
          'Content-Type': 'text/plain',
          'Access-Control-Allow-Origin': '*',
        }
      }
    );

    console.log(paramsResponse.data);
  };

  public static async executeAuction(userAddress: string, base64Bid: string): Promise<[number, string, number[]]> {
    const jsonData = JSON.stringify({ user_bid_address: userAddress, user_bid: base64Bid });

    const response = await axios.post(
      BackendCommunicator.SERVICE_ENDPOINT + '/run_auction',
      jsonData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        }
      }
    );

    console.log(response.data);
    return [response.data["winning_index"], response.data["auction_id"], response.data["other_bids"]];
  }

  public static async reportAuctionPaid(paymentId: string, transactionId: string): Promise<void> {
    const jsonData = JSON.stringify({ auction_id: paymentId, transaction_id: transactionId });
    console.log(jsonData);
    await axios.post(
      BackendCommunicator.SERVICE_ENDPOINT + '/report_payment_submitted',
      jsonData,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        }
      }
    );
  }

  public static async getStakingStatus(accountAddress: string): Promise<[number, string, string]> {
    const response = await axios.post(
      BackendCommunicator.SERVICE_ENDPOINT + '/staking_status',
      accountAddress,
      {
        headers: {
          'Content-Type': 'text/plain',
          'Access-Control-Allow-Origin': '*',
        }
      }
    );

    return [response.data["status"], response.data["slashing_txn"], response.data["payment_txn"]];
  }
}
