import './App.css';
import Header from './components/header';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { AuctionHomeWithWeb3, AuctionHomeWithoutWeb3 } from './AuctionHome';
import { detectMobile } from './components/Shared/utils';


const Home = () => {
  return (
    <div className="w-full">
      <Header />
      <div className="w-full flex">
        <div className="w-full max-w-[1280px] m-auto sm:gap-4 gap-1 md:px-4 px-3 h-[calc(100vh-90px)] flex flex-col justify-center sm:mt-0 -mt-[90px] items-center">
          {/* title -------->  */}
          <p className="text-[30px] leading-[43px] sm:text-[45px] md:text-[68px] text-center sm:leading-14 md:leading-16 uppercase primary-color font-regularText tracking-wider">
            PRIVATE AUCTIONS
          </p>
          {/* Powered by ------> */}
          <p className="text-[15px] sm:text-[20px] md:text-[30px] font-regularText font-medium primary-color">
            Powered by FHE. Learn more <a href="https://blog.sunscreen.tech/building-private-verifiable-auctions/" target="_blank" rel="noreferrer">here</a> and <a href="https://blog.sunscreen.tech/building-a-truly-dark-dark-pool-2/" target="_blank" rel="noreferrer">here</a>.
          </p>
          {/* try it now button ------> */}
          <div className="grid grid-cols-2 gap-10">
          <Link to={detectMobile() ? "#" : "/auctionwithweb3"}
            className="text-center mt-5 cursor-pointer text-[8px] sm:text-[15px] md:text-[24px] font-regularText font-semibold tracking-normal hover:opacity-80 button-colors px-8 py-[6px] sm:py-[8px] rounded-md"
          >
            Blockspace Auction<br/>{detectMobile() ? "(Desktop only)" : ""}
          </Link>
          <a href="https://darkpool.sunscreen.tech/darkpool"
            className="text-center mt-5 cursor-pointer text-[8px] sm:text-[15px] md:text-[24px] font-regularText font-semibold tracking-normal hover:opacity-80 button-colors px-8 py-[6px] sm:py-[8px] rounded-md"
          >
            Dark Pools<br/>{detectMobile() ? "(Desktop only)" : ""}
          </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" Component={Home} />
      <Route path="/auctionwithweb3" Component={AuctionHomeWithWeb3} />
      <Route path="/auctionwithoutweb3" Component={AuctionHomeWithoutWeb3} />
      </Routes>
    </Router>
  );
}


export default App;
