import React, { useEffect, useState } from "react";
import Button from "./Shared/Button";
import Modal from "./Shared/Modal";
import __wbg_init, {
  get_cipher_as_string,
  encrypt_unsigned21,
  get_public_key_from_string,
  initialize_tfhe_context,
  release_cipher,
  release_tfhe_context,
  release_public_key
} from 'sunscreen_tfhe_interop';
import Web3Overlay from "./Web3Overlay";
import { formatNumber } from "./Shared/utils";
import { BackendCommunicator } from "./Shared/BackendCommunicator";
import { InstructionalOverlay } from "./InstructionalOverlay";

type AuctionDemoProps = {
  shouldEnableWeb3: boolean;
}

const AuctionDemo = (props: React.PropsWithChildren<AuctionDemoProps>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [privateBids, setPrivateBids] = useState<number[]>([]);
  const [currentBid, setCurrentBid] = useState(-1);
  const [selectedBidNumber, setSelectedBidNumber] = useState(-1);
  const [selectedBidValue, setSelectedBidValue] = useState(-1);
  const [winningBidNumber, setWinningBidNumber] = useState(-1);
  const [enteredBid, setEnteredBid] = useState("");
  const [errorInBid, setErrorInBid] = useState("");
  const [winningBidPaymentId, setWinningBidPaymentId] = useState("");
  const [accountAddress, setAccountAddress] = useState("");
  const [isExecuting, setIsExecuting] = useState(false);

  const LOW_GWEI_LIMIT = 5000000;
  const HIGH_GWEI_LIMIT = 7000000;

  const STAKING_GWEI = 5000000;

  const TOTAL_BIDS = 93;

  useEffect(() => {
    initializePage();
  }, []);

  const initializePage = async () => {
    await __wbg_init();
  }

  const openModal = async (index: number) => {
    if (index >= privateBids.length) {
      setSelectedBidValue(-1);
    } else {
      setSelectedBidValue(privateBids[index]);
    }
    setSelectedBidNumber(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const setAccount = (address: string) => {
    setAccountAddress(address);
  }

  const executeAuction = async () => {
    try {
      const startTime = new Date().getTime();

      setIsExecuting(true);

      setWinningBidNumber(-1);
      setErrorInBid("Acquiring " + (TOTAL_BIDS - 1) + " other encrypted bids and executing auction using FHE...");
      const context = initialize_tfhe_context();

      const publicKeyBase64 = await BackendCommunicator.getPublicKey();
      const publicKey = get_public_key_from_string(publicKeyBase64);

      const encrypted = encrypt_unsigned21(context, publicKey, BigInt(currentBid));
      const encryptedString = get_cipher_as_string(encrypted);

      release_cipher(encrypted);

      let send_time = new Date().getTime();
      let [winningBidIndex, auctionId, otherBids] = await BackendCommunicator.executeAuction(accountAddress, encryptedString);

      release_tfhe_context(context);
      release_public_key(publicKey);

      setWinningBidNumber(winningBidIndex);
      setWinningBidPaymentId(auctionId);
      setPrivateBids(otherBids);
      if (winningBidIndex === (TOTAL_BIDS - 1)) {
        setErrorInBid("You had the winning bid!");
      } else {
        setErrorInBid("The winning bid was participant number " + winningBidIndex + ".");
      }

      let elapsed = new Date().getTime() - startTime;
      console.log("Time taken by whole execution: " + elapsed);
      console.log("Time taken by auction remote execution: " + (new Date().getTime() - send_time));
    } finally {
      setIsExecuting(false);
    }
  }

  const saveCurrentBid = (proposedBid: string) => {
    try {
      if (proposedBid !== null && proposedBid.length > 0) {
        const parsedValue = parseInt(proposedBid);
        if (parsedValue >= LOW_GWEI_LIMIT && parsedValue <= HIGH_GWEI_LIMIT) {
          setErrorInBid('');
          setCurrentBid(scaleFromGwei(parsedValue));
        } else {
          setErrorInBid("Please enter an integer value between " + formatNumber(LOW_GWEI_LIMIT) + " and " + formatNumber(HIGH_GWEI_LIMIT));
        }
      } else {
        setCurrentBid(-1);
      }
      setEnteredBid(proposedBid);
    } catch {
      setErrorInBid("Please enter an integer value between " + formatNumber(LOW_GWEI_LIMIT) + " and " + formatNumber(HIGH_GWEI_LIMIT));
    }
  }

  const scaleFromGwei = (actual: number) => {
    return actual - LOW_GWEI_LIMIT;
  }

  const scaleToGwei = (actual: number) => {
    if (actual < 0) {
      return actual;
    }
    return actual + LOW_GWEI_LIMIT;
  }

  const resetAuction = async (paymentSuccessful: boolean) => {
    if (!paymentSuccessful) {
      await new Promise(r => setTimeout(r, 2000));
    }

    setWinningBidNumber(-1);
    setSelectedBidNumber(-1);
    setWinningBidPaymentId("");
  }

  return (
    <React.Fragment>
      <div
        id="formButtonSection"
        className="w-full min-h-[calc(100vh-100px)] flex justify-center items-center py-0"
      >
        <div className="w-full h-full max-w-[1320px] m-auto md:px-4 sm:px-3 px-2 flex justify-center items-center">
          <div className="w-full grid grid-cols-9 gap-3">
            <div className="col-span-9 grid grid-cols-9 gap-3">
              {Array.from(Array(36).keys()).map((item, _) => {
                let additionalClass = 'w-full ';
                if (item === winningBidNumber) {
                  additionalClass += " green-background";
                }
                return (
                  <div key={item} className="w-full">
                    <Button event={async () => await openModal(item)} style={additionalClass}></Button>
                  </div>
                );
              })}
            </div>
            <div className="col-span-9 grid grid-cols-9 gap-3">
              <div className="col-span-2 grid grid-cols-2 gap-4">
                {Array.from({ length: 10 }, (_, index) => 36 + index).map((item, _) => {
                  let additionalClass = 'w-full ';
                  if (item === winningBidNumber) {
                    additionalClass += " green-background";
                  }

                  return (
                    <div key={item} className="w-full">
                      <Button event={async () => await openModal(item)} style={additionalClass}></Button>
                    </div>
                  );
                })}
              </div>
              <div className="col-span-5 py-0 flex items-center">
                <InstructionalOverlay>
                  <Web3Overlay
                    stakeGwei={STAKING_GWEI}
                    isUserWinner={winningBidNumber === (TOTAL_BIDS - 1)}
                    usersWinningBid={enteredBid}
                    shouldEnableWeb3={props.shouldEnableWeb3}
                    resetAuction={resetAuction}
                    winningBidPaymentId={winningBidPaymentId}
                    updateAccount={setAccount}>
                    <div className="w-full flex flex-col gap-3 px-2 md:px-4 xl:px-10 items-center">
                      <div className="w-full flex flex-col gap-1">
                        <input
                          type="number"
                          placeholder="Your bid in gwei"
                          className="w-full h-[40px] sm:h-[40px] md:h-[45px] focus:outline-none rounded-md px-3 text-15px sm:text-[15px] md:text-[20px] font-regularText font-semibold text-black"
                          value={enteredBid}
                          onChange={(e) => saveCurrentBid(e.target.value)}
                        />
                      </div>
                      <div className="text-[10px] sm:text-[12px] md:text-[15px]">
                        <>{errorInBid}</>
                        {
                          props.shouldEnableWeb3 && isExecuting &&
                          <><br />
                            <>Please stay online to pay for the auction in case your bid wins.</>
                          </>
                        }
                      </div>
                      <div className="w-full mt-3 flex justify-center items-center">
                        <button className="px-7 py-2 button-colors rounded-md font-semibold hover:opacity-80 text-[10px] sm:text-[15px] md:text-[20px] font-regularText" onClick={() => executeAuction()} disabled={(currentBid < 0) || isExecuting}>
                          Participate In Auction
                        </button>
                      </div>
                    </div>
                  </Web3Overlay>
                </InstructionalOverlay>
              </div>
              <div className="col-span-2 grid grid-cols-2 gap-4">
                {Array.from({ length: 10 }, (_, index) => 46 + index).map((item, _) => {
                  let additionalClass = 'w-full ';
                  if (item === winningBidNumber) {
                    additionalClass += " green-background";
                  }
                  return (
                    <div key={item} className="w-full">
                      <Button event={async () => await openModal(item)} style={additionalClass}></Button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-span-9 grid grid-cols-9 gap-3">
              {Array.from({ length: 36 }, (_, index) => 56 + index).map((item, _) => {
                let additionalClass = 'w-full ';
                if (item === winningBidNumber) {
                  additionalClass += " green-background";
                }
                return (
                  <div key={item} className="w-full">
                    <Button event={async () => await openModal(item)} style={additionalClass}></Button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {
        isModalOpen &&
        <Modal
          closeModal={closeModal}
          selectedBidNumber={selectedBidNumber}
          bidValue={scaleToGwei(selectedBidValue)}
          isWinner={winningBidNumber === selectedBidNumber} />}
    </React.Fragment>
  );
};

export default AuctionDemo;
