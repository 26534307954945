import React, { useState, useEffect, useRef } from 'react';
import { BrowserProvider, parseUnits, Contract } from "ethers";
import { formatNumber, shrinkString } from './Shared/utils';
import { BackendCommunicator } from './Shared/BackendCommunicator';

declare const window: any;

type Web3OverlayProps = {
  stakeGwei: number;
  isUserWinner: boolean;
  shouldEnableWeb3: boolean;
  usersWinningBid: string;
  winningBidPaymentId: string;
  resetAuction: (paymentSuccessful: boolean) => Promise<void>;
  updateAccount: (address: string) => void;
}

enum MetaMaskStates {
  UNINSTALLED = 0,
  INSTALLING = 1,
  INSTALLED = 2
};

enum StakingStates {
  VERIFYING = 0,
  UNSTAKED = 1,
  STAKED = 2,
  STAKED_PENDING_PAYMENT = 3,
  UNSTAKED_SLASHED = 4
}

enum PaymentStates {
  PAYMENT_REQUIRED = 0,
  PAYMENT_PROCESSING = 1,
  PAYMENT_FAILED = 2,
  PAYMENT_TIMEDOUT = 3,
}

const Web3Overlay = (props: React.PropsWithChildren<Web3OverlayProps>) => {
  const [contract, setContract] = useState<any>(null);
  const [wallet, setWallet] = useState<any>(null);
  const [network, setNetwork] = useState("");
  const [account, setAccount] = useState("");
  const [stakingState, setStakingState] = useState(StakingStates.VERIFYING);
  const [isMetamaskInstalled, setIsMetamaskInstalled] = useState(false);
  const [accountConnectionState, setAccountConnectionState] = useState(MetaMaskStates.UNINSTALLED);
  const [networkState, setNetworkState] = useState(MetaMaskStates.UNINSTALLED);
  const [paymentState, setPaymentState] = useState(PaymentStates.PAYMENT_REQUIRED);
  const [wasStakeSlashed, setWasStakedSlashed] = useState(false);
  const [isStakeChangeInProgress, setIsStakeChangeInProgress] = useState(false);
  const [hasAnUpdateBeenPushed, setHasAnUpdateBeenPushed] = useState(false);
  const [cancelTimeoutId, setCancelTimeoutId] = useState<any>(null);
  const [slashingTransactionId, setSlashingTransactionId] = useState<string>("");
  const [paymentTransactionId, setPaymentTransactionId] = useState<string>("");
  const [enableWeb3Features, setEnableWeb3Features] = useState(true);

  const stakingStateRef = useRef<StakingStates>();
  stakingStateRef.current = stakingState;

  const paymentStateRef = useRef<PaymentStates>();
  paymentStateRef.current = paymentState;

  const updatePushedRef = useRef<boolean>();
  updatePushedRef.current = hasAnUpdateBeenPushed;

  const SEPOLIA_CHAIN_ID = '0xaa36a7';

  const CONTRACT_ADDRESS = '0xe86D258f4b52a03ecC22dCb584498f33e2e6051A';
  const CONTRACT_ABI = require('./SunscreenStakingAndSlashing.json');

  useEffect(() => {
    // Check if MetaMask is available
    setEnableWeb3Features(props.shouldEnableWeb3);
    if (typeof window.ethereum !== 'undefined' && window.ethereum.isMetaMask) {
      setIsMetamaskInstalled(true);
      checkCurrentChain();

      // Detect network changes
      window.ethereum.on('chainChanged', (newChainId: string) => {
        setNetwork(newChainId);
      });
    } else {
      setIsMetamaskInstalled(false);
    }
  }, []);

  useEffect(() => {
    setEnableWeb3Features(props.shouldEnableWeb3);
  }, [props.shouldEnableWeb3])

  useEffect(() => {
    // Check if the connected network is Sepolia (You may need to replace the network ID)
    if (network.toLowerCase() === SEPOLIA_CHAIN_ID) {
      setNetworkState(MetaMaskStates.INSTALLED);
    } else {
      if (networkState === MetaMaskStates.INSTALLED) {
        setNetworkState(MetaMaskStates.UNINSTALLED);
      }
    }
  }, [network]);

  useEffect(() => {
    if (account && contract) {
      setTimeout(async () => {
        await verifyStake();
      }, 100);

      contract.on(contract.filters.Slashed(account), () => {
        setWasStakedSlashed(true);
      });
    }
  }, [account, contract])

  useEffect(() => {
    if (account && networkState === MetaMaskStates.INSTALLED) {
      const provider = new BrowserProvider(window.ethereum);
      provider.getSigner(account).then((signer) => {
        const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
        setContract(contract);
        setWallet(signer);
      });
      props.updateAccount(account);
    }

  }, [account, networkState])

  useEffect(() => {
    if (props.isUserWinner) {
      const timeoutId = setTimeout(async () => {
        if (paymentStateRef.current === PaymentStates.PAYMENT_REQUIRED && props.isUserWinner) {
          setPaymentState(PaymentStates.PAYMENT_TIMEDOUT);
          setCancelTimeoutId(null);
          await props.resetAuction(false);
        }
      }, 10000);
      setCancelTimeoutId(timeoutId);
    } else {
      setPaymentState(PaymentStates.PAYMENT_REQUIRED);
    }
  }, [props.isUserWinner]);

  const checkCurrentChain = async () => {
    try {
      const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
      setNetwork(currentChainId);
    } catch (e) {
      setNetwork("");
    }
  }

  const connectAccount = async () => {
    setAccountConnectionState(MetaMaskStates.INSTALLING);
    try {
      // Request access to MetaMask account
      await window.ethereum.request({ method: 'eth_requestAccounts' })
        .then((accounts: string[]) => {
          if (accounts.length > 0 && accounts[0].length > 0) {
            setAccount(accounts[0]);
            setAccountConnectionState(MetaMaskStates.INSTALLED);
          }
        })
        .catch((error: any) => {
          console.error('MetaMask access denied: ', error);
          setAccountConnectionState(MetaMaskStates.UNINSTALLED);
        });
    } catch {
      setAccountConnectionState(MetaMaskStates.UNINSTALLED);
    }
  }

  const changeNetwork = async () => {
    try {
      setNetworkState(MetaMaskStates.INSTALLING);
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: SEPOLIA_CHAIN_ID }],
      });
    } catch (switchError: any) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: SEPOLIA_CHAIN_ID,
                chainName: 'Sepolia',
                rpcUrls: ['https://ethereum-sepolia.publicnode.com'],
              },
            ],
          });
        } catch (addError) {
          setNetworkState(MetaMaskStates.UNINSTALLED);
        }
      } else {
        setNetworkState(MetaMaskStates.UNINSTALLED);
      }
    }
  }

  const verifyStake = async () => {
    try {
      let lastUpdateTime = 0;
      while (true) {
        if (enableWeb3Features &&
          (updatePushedRef.current ||
            (stakingStateRef.current !== StakingStates.STAKED && stakingStateRef.current !== StakingStates.UNSTAKED) ||
            lastUpdateTime + 10000 > new Date().getTime())) {
          const [status, slashing_txn, payment_txn] = await BackendCommunicator.getStakingStatus(account);
          if (status == 0) {
            if (stakingStateRef.current !== StakingStates.STAKED) {
              setIsStakeChangeInProgress(false);
            }
            setStakingState(StakingStates.STAKED);
            setHasAnUpdateBeenPushed(false);
            setSlashingTransactionId("");
            setPaymentTransactionId("");
          } else if (status == 1) {
            if (stakingStateRef.current === StakingStates.STAKED) {
              setIsStakeChangeInProgress(false);
            }
            setStakingState(wasStakeSlashed ? StakingStates.UNSTAKED_SLASHED : StakingStates.UNSTAKED);
            setWasStakedSlashed(false);
            setHasAnUpdateBeenPushed(false);
            setSlashingTransactionId("");
            setPaymentTransactionId("");
          } else {
            setStakingState(StakingStates.STAKED_PENDING_PAYMENT);
            setSlashingTransactionId(slashing_txn);
            setPaymentTransactionId(payment_txn);
          }
          lastUpdateTime = new Date().getTime();
        }
        await new Promise(r => setTimeout(r, 1000));
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const stakeForAuction = async () => {
    setIsStakeChangeInProgress(true);
    const amountToStake = parseUnits("" + props.stakeGwei, "gwei");

    try {
      // Call the staking function on the contract
      setHasAnUpdateBeenPushed(true);
      await contract.stakeFunds.send({
        value: amountToStake,
      });
    } catch (stakingError: any) {
      console.log(stakingError);
      setIsStakeChangeInProgress(false);
    }
  }

  const unstakeForAuction = async () => {
    setIsStakeChangeInProgress(true);
    try {
      setHasAnUpdateBeenPushed(true);
      await contract.withdrawStake();
    } catch (unstakingError) {
      console.log(unstakingError);
      setIsStakeChangeInProgress(false);
    }
  }

  const refundAllMoney = async () => {
    setIsStakeChangeInProgress(true);
    try {
      setHasAnUpdateBeenPushed(true);
      await contract.refundMe();
    } catch (refundingError) {
      console.log(refundingError);
      setIsStakeChangeInProgress(false);
    }
  }

  const payBid = async () => {
    if (cancelTimeoutId != null) {
      clearTimeout(cancelTimeoutId);
      setCancelTimeoutId(null);
    }

    setPaymentState(PaymentStates.PAYMENT_PROCESSING);
    try {
      const amountToPay = parseUnits("" + props.usersWinningBid, "gwei");
      const data = contract.interface.encodeFunctionData(contract.makePaymentAgainstPaymentId.fragment, [props.winningBidPaymentId]);

      // Send the transaction
      const tx = {
        to: CONTRACT_ADDRESS,
        value: amountToPay,
        data: data,
      };

      setHasAnUpdateBeenPushed(true);
      const txResponse = await wallet.sendTransaction(tx);
      console.log("Submitted txn");
      await BackendCommunicator.reportAuctionPaid(props.winningBidPaymentId, txResponse.hash);
      console.log("Alerted service");
      await txResponse.wait();
      console.log("Txn confirmed");
      setPaymentState(PaymentStates.PAYMENT_REQUIRED);
      await props.resetAuction(true);
    } catch (paymentError: any) {
      console.log(paymentError);
      setPaymentState(PaymentStates.PAYMENT_FAILED);
      await props.resetAuction(false);
      setPaymentState(PaymentStates.PAYMENT_REQUIRED);
    }
  }

  const shouldShowStakingUi = () => {
    return isMetamaskInstalled &&
      enableWeb3Features &&
      accountConnectionState === MetaMaskStates.INSTALLED &&
      networkState === MetaMaskStates.INSTALLED &&
      ((isStakeChangeInProgress && stakingState === StakingStates.STAKED) ||
        (stakingState === StakingStates.UNSTAKED) ||
        (stakingState === StakingStates.UNSTAKED_SLASHED) ||
        (stakingState === StakingStates.VERIFYING) ||
        (!props.isUserWinner && stakingState === StakingStates.STAKED_PENDING_PAYMENT));

  }

  const shouldShowPaymentUi = () => {
    return isMetamaskInstalled &&
      enableWeb3Features &&
      accountConnectionState === MetaMaskStates.INSTALLED &&
      networkState === MetaMaskStates.INSTALLED &&
      (stakingState === StakingStates.STAKED || stakingState === StakingStates.STAKED_PENDING_PAYMENT) &&
      props.isUserWinner;

  }

  const shouldShowAuctionUi = () => {
    return (isMetamaskInstalled &&
      accountConnectionState === MetaMaskStates.INSTALLED &&
      networkState === MetaMaskStates.INSTALLED &&
      stakingState === StakingStates.STAKED &&
      !isStakeChangeInProgress &&
      !props.isUserWinner) ||
      !enableWeb3Features;
  }

  return (
    <>
      {
        !isMetamaskInstalled && enableWeb3Features &&
        <div className="w-full mt-3 flex justify-center items-center">
          <div className="grid grid-cols-1 gap-3">
            <h2 className="font-semibold text-[20px] font-regularText">Please install <a target="_blank" href="https://metamask.io/download/" rel="noreferrer">MetaMask</a> and refresh this page.</h2>
            <button className="px-5 py-1 text-blue font-semibold hover:opacity-80 text-[15px] font-regularText" onClick={() => setEnableWeb3Features(false)}>
              Try without connecting your wallet.
            </button>
          </div>
        </div>
      }
      {
        isMetamaskInstalled && enableWeb3Features && accountConnectionState !== MetaMaskStates.INSTALLED &&
        <div className="w-full mt-3 flex justify-center items-center">
          {
            accountConnectionState === MetaMaskStates.UNINSTALLED ?
              <div className="grid grid-cols-1 gap-3">
                <button className="px-7 py-2 button-colors rounded-md font-semibold hover:opacity-80 text-[20px] font-regularText" onClick={async () => await connectAccount()}>
                  Connect account to dApp
                </button>
                <h2 className="text-[12px] font-regularText text-center">Your account will be used for staking and enabling payments for your winning bids.</h2>
              </div> :
              <h2 className="font-semibold text-[20px] font-regularText">Connecting account...</h2>
          }
        </div>
      }
      {
        isMetamaskInstalled && enableWeb3Features && accountConnectionState === MetaMaskStates.INSTALLED && networkState !== MetaMaskStates.INSTALLED &&
        <div className="w-full mt-3 flex justify-center items-center">
          {
            networkState === MetaMaskStates.UNINSTALLED ?
              <button className="px-7 py-2 button-colors rounded-md font-semibold hover:opacity-80 text-[20px] font-regularText" onClick={async () => await changeNetwork()}>
                Change network to Sepolia
              </button> :
              <h2 className="font-semibold text-[20px] font-regularText">Changing network to Sepolia...</h2>
          }
        </div>
      }
      {
        shouldShowStakingUi() &&
        <div className="w-full mt-3 flex justify-center items-center">
          {
            (stakingState === StakingStates.UNSTAKED || stakingState === StakingStates.UNSTAKED_SLASHED) && isStakeChangeInProgress &&
            <h2 className="font-semibold text-[20px] font-regularText">Staking...</h2>
          }
          {
            (stakingState === StakingStates.UNSTAKED || stakingState === StakingStates.UNSTAKED_SLASHED) && !isStakeChangeInProgress &&
            <div className="grid grid-cols-1 gap-3">
              <button className="px-7 py-2 button-colors rounded-md font-semibold hover:opacity-80 text-[20px] font-regularText" onClick={async () => await stakeForAuction()}>
                Stake {formatNumber(props.stakeGwei)} Gwei to bid
              </button>
              <h2 className="justify-center items-center text-[12px] font-regularText col-span-1 text-center">Staking is used to protect against bad behavior from participants but is entirely separate from bid payment.</h2>
              <h2 className="justify-center items-center text-[12px] font-regularText col-span-1 text-center">If you do not pay your winning bids, your stake will be slashed.</h2>
            </div>
          }
          {
            stakingState === StakingStates.STAKED && isStakeChangeInProgress &&
            <h2 className="font-semibold text-[20px] font-regularText">Unstaking...</h2>
          }
          {
            stakingState === StakingStates.VERIFYING &&
            <h2 className="font-semibold text-[20px] font-regularText">Verifying Stake...</h2>
          }
          {
            stakingState === StakingStates.STAKED_PENDING_PAYMENT && !slashingTransactionId && !paymentTransactionId &&
            <div>
              <h2 className="font-semibold text-[20px] font-regularText">Your stake is being slashed.</h2>
            </div>
          }
          {
            stakingState === StakingStates.STAKED_PENDING_PAYMENT && slashingTransactionId && !paymentTransactionId &&
            <div>
              <h2 className="font-semibold text-[20px] font-regularText">Your stake is being slashed.</h2>
              <a target="_blank" rel="noreferrer" href={"https://sepolia.etherscan.io/tx/0x" + slashingTransactionId}>Click here to view status of the pending slashing transaction.</a>
            </div>
          }
          {
            stakingState === StakingStates.STAKED_PENDING_PAYMENT && !slashingTransactionId && paymentTransactionId &&
            <div>
              <h2 className="font-semibold text-[20px] font-regularText">Your payment is being processed</h2>
              <a target="_blank" rel="noreferrer" href={"https://sepolia.etherscan.io/tx/" + paymentTransactionId}>Click here to view status of the pending payment transaction.</a>
            </div>
          }
          {
            stakingState === StakingStates.STAKED_PENDING_PAYMENT && slashingTransactionId && paymentTransactionId &&
            <div>
              <h2 className="font-semibold text-[20px] font-regularText">It seems your payment was late and we have started to slash your stake.</h2>
              <a target="_blank" rel="noreferrer" href={"https://sepolia.etherscan.io/tx/" + paymentTransactionId}>Click here to view status of the pending payment transaction.</a>
              <a target="_blank" rel="noreferrer" href={"https://sepolia.etherscan.io/tx/0x" + slashingTransactionId}>Click here to view status of the slashing transaction.</a>
            </div>
          }
        </div>
      }
      {
        shouldShowPaymentUi() &&
        <div className="w-full mt-3 flex justify-center items-center">
          {
            paymentState === PaymentStates.PAYMENT_REQUIRED &&
            <div className="w-full grid grid-cols-1 gap-3">
              <h2 className="font-semibold text-[20px] font-regularText col-span-1">Your bid was the winner.</h2>
              <h2 className="font-semibold text-[20px] font-regularText col-span-1">You have 10 seconds to pay before your stake is slashed.</h2>
              <br />
              <button className="col-span-1 px-7 py-2 button-colors rounded-md font-semibold hover:opacity-80 text-[20px] font-regularText" onClick={async () => await payBid()}>
                Pay via MetaMask
              </button>
            </div>
          }
          {
            paymentState === PaymentStates.PAYMENT_PROCESSING &&
            <h2 className="font-semibold text-[20px] font-regularText">Processing payment...</h2>
          }
          {
            paymentState === PaymentStates.PAYMENT_FAILED &&
            <h2 className="font-semibold text-[20px] font-regularText">Your payment failed. Your stake will be slashed.</h2>
          }
          {
            paymentState === PaymentStates.PAYMENT_TIMEDOUT &&
            <h2 className="font-semibold text-[20px] font-regularText">You ran out of time. Your stake will be slashed.</h2>
          }
        </div>
      }
      {
        shouldShowAuctionUi() &&
        <div className="w-full mt-3 flex justify-center items-center">
          <div className="w-full flex flex-col gap-3 px-2 md:px-4 xl:px-10 items-center">
            {props.children}
            {enableWeb3Features &&
              <div className="w-full grid grid-cols-4 gap-3">
                <div className="col-span-3">
                  <h3><b>Account ID</b>: {shrinkString(account)}</h3>
                  <h3><b>Network ID</b>: {network} Sepolia</h3>
                </div>
                <div className="col-span-1">
                  <div className="grid grid-cols-1 gap-1">
                    <h3><b>Staked</b>: {formatNumber(props.stakeGwei)}</h3>
                    <button className="w-full px-3 py-1 button-colors rounded-md font-semibold hover:opacity-80 text-[12px] font-regularText" onClick={async () => await unstakeForAuction()}>
                      Unstake
                    </button>
                    <button className="w-full px-3 py-1 button-colors rounded-md font-semibold hover:opacity-80 text-[12px] font-regularText" onClick={async () => await refundAllMoney()}>
                      Refund and Exit
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      }
    </>
  );
};

export default Web3Overlay;
