import React from 'react';

const isSafari = /Version\/[\d.]+.*Safari/.test(navigator.userAgent);
const supportedBrowsers = !isSafari;

const BrowserChecker = ({ children }: any) => {
  if (!supportedBrowsers) {
    return (
      <div className="w-full flex">
        <div className="w-full max-w-[1280px] m-auto sm:gap-4 gap-1 md:px-4 px-3 h-[calc(100vh-90px)] flex flex-col justify-center sm:mt-0 -mt-[90px] items-center">
          <h2 className="font-semibold text-[20px] font-regularText">Error: This application is not supported in Safari. Please use Chrome, Firefox, Edge, or Android browsers.</h2>;
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default BrowserChecker;
