import './App.css';
import Header from './components/header';
import Auction from './components/auction';
import BrowserChecker from './components/BrowerChecker';

export function AuctionHomeWithWeb3() {
  return (
    <div className="w-full">
      <Header />
      <BrowserChecker>
        <Auction shouldEnableWeb3={true}/>
      </BrowserChecker>
    </div>
  );
}

export function AuctionHomeWithoutWeb3() {
  return (
    <div className="w-full">
      <Header />
      <BrowserChecker>
        <Auction shouldEnableWeb3={false}/>
      </BrowserChecker>
    </div>
  );
}
