import React from "react";
import { formatNumber } from "./utils";

interface Props {
  closeModal: () => void;
  selectedBidNumber: number;
  bidValue: number;
  isWinner: boolean
}

const Modal = ({ closeModal, selectedBidNumber, bidValue, isWinner }: Props) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className="standard-background inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          style={{ maxWidth: "500px", height: "180px" }}
        >
          <div className="pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex justify-start items-center">
              <div className="text-left sm:mt-0">
                {bidValue >= 0 &&
                  <>
                    <h3 className="text-[10px] sm:text-[12px] md:text-[15px] leading-6 font-medium primary-color">
                      Participant {selectedBidNumber} bid {formatNumber(bidValue)} gwei.
                    </h3>
                    {
                      !isWinner &&
                      <div className="text-[10px] sm:text-[12px] md:text-[15px] primary-color">
                        *This bid is decrypted for demo purposes only.
                      </div>
                    }</>
                }
                {bidValue < 0 &&
                  <h3 className="text-[10px] sm:text-[12px] md:text-[15px] leading-6 font-medium primary-color">
                    This bid is not available yet. It will be populated once the auction is executed.
                  </h3>
                }
              </div>
            </div>
          </div>
          <button
            onClick={closeModal}
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-5 py-2 button-colors text-[10px] sm:text-[12px] md:text-[15px] font-regularText font-bold hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-6 sm:w-auto"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
