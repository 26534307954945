import { useState } from "react";
import curvedArrow from "../curved-arrow.png";
type InstructionalOverlayProps = {
}
export const InstructionalOverlay = (props: React.PropsWithChildren<InstructionalOverlayProps>) => {
  const [isDismissed, setIsDismissed] = useState(false);

  return (
    <div className="w-full h-full flex justify-center items-center relative z-0">
      {props.children}
      {
        !isDismissed &&
        <div className="absolute inset-0 flex bg-opacity-90 bg-gray-300 z-10 rounded-md">
          <div className="grid grid-cols-1 w-full">
            <div className="px-2 py-2 sm:px-3 md:py-3 md:px-5 md:py-5">
              <img src={curvedArrow} className="w-5 sm:w-15 md:w-20 float-left mr-1 sm:mr-2 md:mr-3" />
              <h2 className="mt-3 sm:mt-7 md:mt-10 py-2 text-[10px] sm:text-[15px] md:text-[20px] font-regularText font-semibold text-black">Encrypted bids from 92 other auction participants</h2>
            </div>
            <div className="px-1 py-1 sm:px-3 md:py-3 md:px-5 md:py-5">
              <button className="absolute bottom-5 right-5 w-[50px] sm:w-[75px] md:w-[100px] h-8 sm:h-10 md:h-12 button-colors rounded-md font-semibold hover:opacity-80 text-[10px] sm:text-[15px] md:text-[20px] font-regularText" onClick={() => setIsDismissed(true)}>
                Got it
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  )
}