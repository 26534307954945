export function formatNumber(input: number): string {
  if (input) {
    return input.toLocaleString("en-US");
  }

  return "";
}

export function shrinkString(input: string): string {
  if (!input || input.length <= 10) {
    return input; // No need to shrink the string
  } else {
    // Truncate the string to the specified length and add ellipses
    return input.substring(0, 5) + '...' + input.substring(input.length - 4);;
  }
}

export function detectMobile(): boolean {
  // Check if the user agent indicates a mobile device
  const userAgent = navigator.userAgent;
  const mobileKeywords = [
    'Android',
    'webOS',
    'iPhone',
    'iPad',
    'iPod',
    'BlackBerry',
    'Windows Phone',
  ];

  return mobileKeywords.some(keyword => userAgent.includes(keyword));
}